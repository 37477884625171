// token details
const contracts = {
    lpStaking: {
        contract: "secret1f75jf2yxnkdsxsyverzuxk7a260jyqzgm8g9ka",
        hash: "79c3012bb59ab1c5139148a1acc9160d32b04152e6363f2961bcebb68974f988",
    },
};


export default contracts;


